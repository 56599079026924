/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_kS4YqvCDh",
    "aws_user_pools_web_client_id": "7n6nrpep2vqmk097uspvlsdd1i",
    "oauth": {}
};


export default awsmobile;
